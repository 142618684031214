//
// Search engine component, composits widgets used for the main search engine form
//
import LocationSelect from './location-select';
import Dropdown from './dropdown';
import ToggleSelect from './toggle-select';

export default class SearchEngine {
  constructor( element, propertyTypeID='id_property_type', transactionTypeID='id_transaction_type') {
    this.element = element;
    this.propertyTypeID = propertyTypeID;
    this.transactionTypeID = transactionTypeID;
    this.initialize();
  }

  initialize() {
    let $el = $(this.element),
        $transactionToggle;

    // Initialize widgets
    new LocationSelect($el.find('.location-select').get(0), false);
    new Dropdown($el.find('select#' + this.propertyTypeID));
    new Dropdown($el.find('select#' + this.transactionTypeID));

    $transactionToggle = $el.find('.transaction-type-switch#' + this.transactionTypeID);
    if ($transactionToggle.length > 0) {
      new ToggleSelect($transactionToggle);
    }
  }
}
