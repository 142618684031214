// Lightweight wrapper for dropdowns
import $ from 'jquery';

import 'bootstrap-select';
import 'bootstrap-select/sass/bootstrap-select.scss'

import '../../scss/components/dropdown.scss';

const DATA_KEY = 'rebs.dropdown';
const CARET_WRAPPER_HTML = '<span class="dropdown-arrow"></span>';

export default class Dropdown {
  constructor( element ) {
    this.element = element;
    this.initialize();
    $(this.element).closest('.bootstrap-select').removeClass('dropdown-placeholder');
    $(this.element).data(DATA_KEY, this);
  }

  removeEmptyOptions() {
    $(this.element).find('option[value=""]').attr('disabled', 'true');
  }

  reset () {
    $(this.element).selectpicker('val', '');
  }

  initialize() {
    this.removeEmptyOptions();
    $(this.element).selectpicker({
      dropupAuto: false,
      display: 'static',
      style: '',
      styleBase: 'form-control',
    });

    // Add our custom caret
    $(CARET_WRAPPER_HTML)
      .html(kairos.config.DROPDOWN_CARET_ICON)
      .appendTo(
        $(this.element).siblings('.dropdown-toggle')
      );
  }
}
