// Widget used for selecting locations
import 'bootstrap';
import 'bootstrap-autocomplete';
import Cookies from 'js-cookie';
import '../../scss/components/location-select.scss';

const DATA_KEY = 'rebs.location-select';
const PLACEHOLDER = "Zonă / ID proprietate";
const PLACEHOLDER_MULTIPLE = "Zone";
const PLACEHOLDER_MULTIPLE_ADD = "Adaugă o zonă nouă";
const ZONE_FIELD_NAME = 'zone';
const CITY_FIELD_NAME = 'city';
const REGION_FIELD_NAME = 'region';
const SEARCH_FIELD_NAME = 'property_id';
const SELECTOR_PICKER = '.picker';
const SELECTOR_RESULTS = '.picker-results';
const CLOSE_HANDLE = $('<a>').attr('href', '#').html(kairos.config.CLOSE_ICON);

export default class LocationSelect {
  constructor( element, multiple, cacheOnBack ) {
    this.element = element;
    this.multiple = multiple || false;
    this.selection = [];
    this.cacheOnBack = cacheOnBack || false;
    this.initialize();
  }

  initialize() {
    let $el = $(this.element),
        plugin = this,
        $picker = $el.find(SELECTOR_PICKER);

    // This plugin has 3 main components
    // => A picker, using an autocomplete plugin
    this.picker = $picker.autoComplete({
      minLength: 0,
      noResultsText: '',
      preventEnter: this.multiple, // Prevent enter only in multiselect mode
      resolver: 'custom',
      events: {
        search: async (q, callback) => {
          let formData = new FormData();
          formData.append('q', $picker.val());
          const source = await fetch(kairos.config.LOCATIONS_ENDPOINT, {
            method: 'POST',
            headers : {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRFToken': Cookies.get('csrftoken')
            },
            body: formData,
          });
          const data = await source.json();
          callback(data);
        },
        searchPost: (results) => {
          if (!this.multiple) {
            return results;
          }
          return results.filter(item => {
            // Include result if not already selected
            return !this.selection.some(s => (s.type === item.type && s.id === item.id))
          });
        }
      },
      formatResult: (item) => {
        return {
          value: item.id,
          text: item.label,
        };
      }
    });


    // => a picker results section (only in multiselect mode)
    if (this.multiple) {
      this.results = $el.find(SELECTOR_RESULTS);
      this.results.removeClass('d-none');
    }

    // => and some hidden inputs used to relay the selection to the form
    this.searchId = $el.find('input').filter((i,e) => e.name == SEARCH_FIELD_NAME );
    this.zone = this.initializeSelect(ZONE_FIELD_NAME);
    this.city = this.initializeSelect(CITY_FIELD_NAME);
    this.region = this.initializeSelect(REGION_FIELD_NAME);

    this.restoreCachedSelection();
    this.addEventHandlers();
    this.updatePickerPlaceholder();
  }

  /**
   * @return {bool} True if at least an element is selected
   */
  hasSelection() {
    return this.selection.length > 0;
  }

  onSelectionChange() {
    this.picker.toggleClass(this.multiple ? 'has-selection' : 'has-single-selection', this.hasSelection());
    this.searchId.val("");
    this.cacheSelection();
  }

  /**
   * Updates the picker's placeholder depending on selection mode, items selected
   */
  updatePickerPlaceholder() {
    let ph = PLACEHOLDER;
    if (this.multiple) {
      // More than one item selected
      if (this.hasSelection()) {
        ph = PLACEHOLDER_MULTIPLE_ADD;
      } else {
        ph = PLACEHOLDER_MULTIPLE;
      }
    }
    this.picker.attr('placeholder', ph);
  }

  /**
   * Fetches a select with `name`
   * @param {name} string The name of the select
   * @returns {$Object} A hidden select for `name` type selection
   */
  getSelect(name) {
    return $(this.element).find('select').filter((i,e) => e.name == name );
  }

  /**
   * Initializes a select and parses any selected options for `name` type selections
   * eg. a select that holds only cities, or zones
   * @param {string} name The name of the select
   */
  initializeSelect(name) {
    var $el = $(this.element),
        $select = this.getSelect(name),
        plugin = this;

    if (this.multiple) {
      // Fetch all rendered options, add them pre-selected to the picker
      $select.find('option').each((index, option) => {
        let $option = $(option);
        this.addItemToPicker({
          id: parseInt($option.attr('value')),
          name: $option.attr('name'),
          type: $option.attr('type'),
          label: $option.attr('label'),
        });
      });
    }

    return $select;
  }

  /**
   * Returns the hidden select option that correspond to a selected item
   * @param   {Object}  item   A selectable item
   * @returns {$Object}        An option inside the selects, corresponding to item
   *                           -or- null if the option doesn't exist
   */
  getSelectOption(item) {
    let select = this.getSelect(item.type),
        $options = select.find('option').filter((i,e) => e.value == item.id);

    if ($options.length > 0) {
      return $options[0];
    }
    return null;
  }

  /**
   * Add an item to its corresponding hidden select, if it doesn't exist already
   * @param   {Object} item   A selectable item
   * @returns {bool}          true if the item was added
   *                          -or- false if the item existed
   */
  addItemToHiddenSelects(item) {
    let select = this.getSelect(item.type);

    // Allow one single hidden select if not in multiple mode
    if (!this.multiple) {
      this.cleanHiddenSelects();
    }

    // Only add to the hidden select if it's not existing
    if (!this.getSelectOption(item)) {
      $("<option>").attr('value', item.id)
                   .attr('selected', true)
                   .appendTo(select);
      return true;
    }
    return false;
  }

  /**
   * Clears all hidden inputs
   */
  cleanHiddenSelects() {
    // Clean existing hidden options
    this.zone.find('option').remove();
    this.city.find('option').remove();
    this.region.find('option').remove();
  }

  /**
   * Clears picker, hidden selects and selection, for single select mode
   */
  clearSingleSelection() {
    this.picker.autoComplete('clear');
    this.cleanHiddenSelects();
    this.selection = [];
    this.onSelectionChange();
  }

  /**
   * Add event handlers for the picker
   */
  addEventHandlers() {
    // On item selected -
    this.picker.on('autocomplete.select', (event, item) => {
      // - if item not in the hidden selects, add it and mark it as selected
      if (this.addItemToHiddenSelects(item)) {
        this.addItemToPicker(item);
      }
      // - in multiple mode, clear the picker every time
      if (this.multiple) {
        this.picker.autoComplete('clear');
        this.updatePickerPlaceholder();
      }
    });
    // On clearing text in single select mode, remove all selections
    if (!this.multiple) {
      this.picker.on('input', (event, item) => {
        if (this.picker.val() === "") {
          this.clearSingleSelection();
        } else {
          this.cleanHiddenSelects();
          this.selection = [];
          this.onSelectionChange();
        }
      });
    }
    // On removing a result
    if (this.multiple) {
      this.results.on('click', 'li a', (event) => {
        let $result = $(event.target).closest('.picker-result');
        event.preventDefault();
        this.removeItem({
          type: $result.attr('type'),
          id: parseInt($result.attr('id')),
        });
        $result.remove();
      });
    }
    // On entering text, add it to the custom search hidden input
    this.picker.on('autocomplete.freevalue', (event, text) => {
      // Only if no other options are selected, and only in single select mode
      if (!this.hasSelection() && !this.multiple) {
        this.searchId.val(text);
      }
    });
    // Patch for selecting the first item automatically
    const dd = this.picker.data("autoComplete")._dd;
    dd._refreshItemList = dd.refreshItemList;
    dd.refreshItemList = () => {
      dd._refreshItemList();
      dd.focusNextItem();
    };
    // Add class when dropdown is open
    this.picker.on('autocomplete.dd.shown', (event) => {
      $(this.element).addClass('showing-suggestions');
    });
    // Remove when closed
    this.picker.on('autocomplete.dd.hidden', (event) => {
      $(this.element).removeClass('showing-suggestions');
    });
    // Open on focus
    this.picker.on('focus', (event) => {
      if (this.hasSelection() && !this.multiple) {
        this.picker.select();
      }
      this.picker.autoComplete('show');
    });
  }

  /**
   * Removes all selected items, results and cleans selects
   */
  reset() {
    for (var i = this.selection.length - 1; i >= 0; i--) {
      this.removeItem(this.selection[i]);
    }
    this.cleanHiddenSelects();
    this.results.html('');
  }

  /**
   * Adds an item selected by the picker, to the results panel and adds
   * it to 'this.selection'
   * @param {Object}  A JSON object representing a selectable item
   */
  addItemToPicker(item) {
    let $result = $("<li>");
    $result.addClass('picker-result')
           .attr('type', item.type)
           .attr('id', item.id)
           .html([
              item.label,
              CLOSE_HANDLE.clone(),
            ]);

    if (this.multiple) {
      this.results.append($result);
      // When first adding results, add a class that applies paddings etc., this helps prevent CLS
      this.results.addClass('has-results');
      this.selection.push(item);
      this.onSelectionChange();
    } else {
      this.selection = [ item ];
      this.onSelectionChange();
    }
  }

  /**
   * Removes an item from the current selection internal index
   * @param  {Object}   A selectable item with at leasty id & type
   */
  removeItem(item) {
    // Remove from hidden selects
    this.getSelectOption(item).remove();
    // Remove from selection
    if (this.multiple && this.selection) {
      this.selection = this.selection.filter(s => !(s.type === item.type && s.id === item.id));
      this.onSelectionChange();
    }
    this.updatePickerPlaceholder();
  }

  /**
   * If 'this.cacheOnBack' is enabled, will create a localstorage entry containing
   * the seletion that was made on a given page. Note that:
   * - there is only one entry per website, so only one widget can cache its selection
   * - if localstorage is not availabile, this does nothing
   * @param  {bool}   If true, will clear any existing selection and exit
   */
  cacheSelection(clear) {
    if (!this.cacheOnBack) { return; }
    if (typeof Storage !== 'undefined') { // Check for local storage support
      if (clear) {
        localStorage.cachedSelection = null;
        return;
      }
      localStorage.cachedSelection = JSON.stringify({
        selection: this.selection,
        from: window.location,
      });
    }
  }

  /**
   * If 'this.cacheOnBack' is enabled, this will look for a cached selection inside localstorage, and,
   * ~if the location where the selection was cached matches this page, and there is no current selection~
   * will set the selection to the cached selection, update the hidden selects and the text input.
   *
   * This serves one single purpose, to preserve selected location data when pressing back on the browser.
   * Note that this will also preserve location information if you change several pages and then get back to
   * the original page the cached selection was made for. ¯\_(ツ)_/¯
   *
   * @return {Object} The cached selection information
   */
  restoreCachedSelection() {
    if (!this.cacheOnBack) { return; }
    if (typeof Storage !== 'undefined') {
      let cache;
      try {
        cache = JSON.parse(localStorage.cachedSelection);
        this.cacheSelection(true);
      } catch (e) {
        this.cacheSelection(true);
        return null;
      }
      if (!cache) { return; }
      // If selection was cached from the same page
      if (cache.from.href === window.location.href) {
        if (!this.hasSelection() && cache.selection && cache.selection.length > 0) {
          this.addItemToHiddenSelects(cache.selection[0]);
          this.selection = cache.selection;
          this.onSelectionChange();
          this.picker.autoComplete('set', cache.selection[0]);
        }
      }
      return cache;
    }
  }

}
