// Simple toggle selects
export default class ToggleSelect {
  constructor( element ) {
    this.element = element;
    this.initialize();
  }

  reset () {
    // Reset is not implemented for toggle selects
  }

  initialize() {
    let $el = $(this.element),
        $toggles = $el.find('.nav-item a'),
        $input = $el.find('input');

    if ($input.val()) {
      $el.addClass('has-value');
    }

    $toggles.on('click', (event) => {
      let selectedValue = $(event.target).attr('value');
      $input.val(selectedValue);
      $el.addClass('has-value');
    });
  }
}
